.playfair-display {
    font-family: "Playfair Display", serif;
    font-optical-sizing: auto;
    font-style: normal;
}

.FormSection {
    padding: 20px 20px 20px 20px;
    border-radius: 0px 50px 0px 50px;
    background-color: #9ACCC9;
}

.FormSection img {
    border: 5px solid #fff;
    border-radius: 0px 50px 0px 0px;
    box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.1);
}


.SocialIconFooter {
    margin-top: 10px;
    margin-bottom: 10px;
}

.SocialIconFooter i {
    background-color: #2BC0C1;
    width: 35px;
    height: 35px;
    font-size: 14px;
    color: #fff;
    /* margin-left: 10px; */
    padding: 10px;
    border-radius: 25px;
}

.SocialIconFooter i:hover {
    background-color: #F4A492;
    width: 35px;
    height: 35px;
    font-size: 14px;
    color: #fff;
    transform: scale(1.1);
    transition: all 0.5s;
    /* margin-left: 10px; */
    padding: 10px;
    border-radius: 25px;
}

.MainContactSectionFirst {
    height: 100%;
    background-color: #ffffff;
    align-items: center;
    border-bottom: 5px solid #fff;
    padding: 20px;
    border-radius: 50px 0px 0px 0px;
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
}


.MainContactSectionFirst:hover {
    height: 100%;
    background-color: #ffffff;
    align-items: center;
    border-bottom: 5px solid #F4A492;
    padding: 20px;
    border-radius: 50px 0px 0px 0px;
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
}

.MainContactSectionSecond {
    height: 100%;
    background-color: #ffffff;
    align-items: center;
    border-bottom: 5px solid #fff;
    padding: 20px;
    border-radius: 0px 0px 0px 0px;
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
}

.MainContactSectionSecond:hover {
    height: 100%;
    background-color: #ffffff;
    align-items: center;
    border-bottom: 5px solid #F4A492;
    padding: 20px;
    border-radius: 0px 0px 0px 0px;
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
}

.MainContactSectionThird {
    height: 100%;
    background-color: #ffffff;
    align-items: center;
    border-bottom: 5px solid #fff;
    padding: 20px;
    border-radius: 0px 0px 50px 0px;
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
}

.MainContactSectionThird:hover {
    height: 100%;
    background-color: #ffffff;
    align-items: center;
    border-bottom: 5px solid #F4A492;
    padding: 20px;
    border-radius: 0px 0px 50px 0px;
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
}

.ContactBottomSectionFirst {
    padding: 14px 0px 0px 0px;
    width: 50px;
    height: 50px;
    background-color: #9ACCC9;
    border-radius: 100px;
}

.ContactBottomSectionFirst:hover {
    padding: 14px 0px 0px 0px;
    width: 50px;
    height: 50px;
    background-color: #F4A492;
    border-radius: 100px;
}

.ContactBottomSectionFirst i {
    font-size: 18px;
    margin-left: 15px;
}

.ContactBottomSection {
    padding: 10px;
    font-family: "Playfair Display", serif;
}

.ContactBottomSection h4 {
    font-size: 20px;
    color: #111;
    margin-left: 20px;
    font-weight: 600;
    line-height: 1.1em;
}

.ContactBottomSection h4:hover {
    font-size: 20px;
    color: #F4A492;
    margin-left: 20px;
    font-weight: 600;
    line-height: 1.1em;
}

.CommonHoverContact {
    font-size: 17px;
    color: #555555;
    line-height: 20px;
    font-weight: 500;
}

.CommonHoverContact:hover {
    font-size: 17px;
    color: #F4A492;
    line-height: 20px;
    font-weight: 500;
}

.CommonHoverContactAdrres {
    font-size: 17px;
    color: #555555;
    line-height: 20px;
    font-weight: 500;
}

@media screen and (max-width: 990px) {
    .ContactBottomSectionFirst i {
        font-size: 18px;
        margin-left: 0px;
    }

}

@media screen and (max-width: 767px) {


    .SocialIconFooter {
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 5px;
    }

    .SocialIconFooter i {
        background-color: #2BC0C1;
        width: 38px;
        height: 38px;
        font-size: 16px;
        color: #fff;
        margin-left: 20px;
        padding: 10px;
        border-radius: 25px;
    }

    .SocialIconFooter i:hover {
        background-color: #fff;
        width: 38px;
        height: 38px;
        font-size: 16px;
        color: #202734;
        margin-left: 20px;
        padding: 10px;
        border-radius: 25px;
    }


    .ContactBottomSectionFirst i {
        font-size: 18px;
        margin-left: 15px;
    }

}

@media screen and (max-width: 580px) {
    .ContactBottomSectionFirst i {
        font-size: 18px;
        margin-left: 1px;
    }

}
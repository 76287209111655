.playfair-display {
    font-family: "Playfair Display", serif;
    font-optical-sizing: auto;
    font-style: normal;
}

.laila-semibold {
    font-family: "Laila", serif;
    font-weight: 600;
    font-style: normal;
}

.AbouTMainImg {
    height: 500px;
}

.about-1 img {
    border: 5px solid #fff;
    border-radius: 0px 50px 0px 50px;
    box-shadow: -15px 15px 100px -53px rgba(0, 0, 0, 0.5);
}

.AboutSet2-1 img {
    width: 420px;
    border: 5px solid #fff;
    border-radius: 50px 0px 0px 0px;
    box-shadow: -15px 15px 100px -53px rgba(0, 0, 0, 0.5);
}

.AboutSet2-2 img {
    width: 240px;
    border: 5px solid #fff;
    border-radius: 0px 50px 0px 50px;
    box-shadow: -20px -10px 100px -50px rgba(0, 0, 0, 0.5)
}


.AboutSet2-3 img {
    width: 240px;
    border: 5px solid #fff;
    margin-top: 20px;
    border-radius: 0px 50px 0px 50px;
    box-shadow: 6px -16px 100px -31px rgba(0, 0, 0, 0.5);
}

.AboutHeartsUpSecond {
    position: relative;
}

.AboutHeartsDownSecond {
    position: absolute;
    top: -120px;
    right: 10%;
}

.MainWidth {
    width: 200px;
}

.CommonBorder {
    border: 1px solid #fff;
    height: 90px;
}

.progress-bar {
    background-color: #9ACCC9;
}

.ProgressBar h2 {
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: start;
    font-family: "Playfair Display", serif;
}

.AboutSectionSecondbirdUp {
    position: relative;
}

.AboutSectionSecondbirdDown {
    position: absolute;
    top: -100px;
    left: -25%;
}

@media screen and (max-width: 990px) {
    .AboutHeartsUpSecond {
        position: relative;
    }

    .AboutHeartsDownSecond {
        position: absolute;
        top: -10px;
        right: 0%;
    }

    .AbouTMainImg {
        height: 100%;
    }


    .AboutSet2-1 img {
        width: 320px;
        border: 5px solid #fff;
        border-radius: 50px 0px 0px 0px;
        box-shadow: -15px 15px 100px -53px rgba(0, 0, 0, 0.5);
    }

    .AboutSet2-2 img {
        width: 160px;
        border: 5px solid #fff;
        border-radius: 0px 50px 0px 50px;
        box-shadow: -20px -10px 100px -50px rgba(0, 0, 0, 0.5)
    }


    .AboutSet2-3 img {
        width: 170px;
        border: 5px solid #fff;
        margin-top: 20px;
        border-radius: 0px 50px 0px 50px;
        box-shadow: 6px -16px 100px -31px rgba(0, 0, 0, 0.5);
    }

}

@media screen and (max-width: 767px) {
    

    .AbouTMainImg {
        height: 100%;
    }

}

@media screen and (max-width: 450px) {
    

    .CommonBorder {
        border: 1px solid #fff;
        margin-left: 20px;
        height: 90px;
    }

}

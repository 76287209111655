.playfair-display {
    font-family: "Playfair Display", serif;
    font-optical-sizing: auto;
    font-style: normal;
}

.laila-semibold {
    font-family: "Laila", serif;
    font-weight: 600;
    font-style: normal;
}

.MainImg {
    height: 1200px;
}

.HomeContentMain {
    position: relative;
}

.HomeContent {
    position: absolute;
    font-family: "Playfair Display", serif;
    /* font-family: "Merriweather", serif; */
    left: 8%;
    top: 15%;
    color: #fff;
    padding-top: 50px;
}

.HomeSeT img {
    width: 93%;
    border: 5px solid #fff;
    border-radius: 100px 0px 50px 0px;
}

.HomeSeTcontent {
    margin-left: 0px;
}

.HomeSeTcontent h2 {
    font-size: 55px;
    color: #000000;
    font-weight: 700;
    margin-bottom: 1rem;
    letter-spacing: 1px;
}

.HomeSeTcontent p {
    font-size: 20px;
    margin-bottom: 2rem;
    color: #818181;
    font-weight: 400;
}

.HomeSeTcontent button {
    background-color: #F4A492;
    border: 2px solid #F4A492;
    padding: 16px 42px;
    font-size: 17px;
    color: #fff;
    border-radius: 100px;
}

.HomeSeTcontent button:hover {
    background-color: #9ACCC9;
    border: 2px solid #9ACCC9;
    padding: 16px 42px;
    font-size: 17px;
    color: #fff;
    border-radius: 100px;
}

.HomeSet2-1 img {
    width: 420px;
    border: 5px solid #fff;
    border-radius: 0px 50px 0px 0px;
    box-shadow: -15px 15px 100px -53px rgba(0, 0, 0, 0.5);
}

.HomeSet2-2 img {
    width: 250px;
    border: 5px solid #fff;
    border-radius: 50px 0px 50px 0px;
    box-shadow: -20px -10px 100px -50px rgba(0, 0, 0, 0.5)
}

.HomeSet2-3 img {
    border: 5px solid #fff;
    margin-top: 20px;
    border-radius: 50px 0px 50px 0px;
    box-shadow: 6px -16px 100px -31px rgba(0, 0, 0, 0.5);
}

.AboutSectionHome h6 {
    color: #F4A492;
    font-size: 18px;
    font-family: "Laila", serif;
    margin-bottom: 20px;
}

.AboutSectionHome h3 {
    color: #000000;
    font-size: 2.5rem;
    font-weight: 700;
    font-family: "Playfair Display", serif;
    margin-bottom: 20px;
}

.AboutSectionHome h5 {
    color: #555555;
    font-size: 1rem;
    font-weight: 400;
    font-family: "Laila", serif;
    margin-bottom: 20px;
}

.AboutSectionHome p {
    color: #555555;
    font-size: 16.5px;
    font-weight: 400;
    font-family: "Laila", serif;
    margin-bottom: 25px;
}

.AboutSectionHome button {
    background-color: #fff;
    border: 1px solid #9ACCC9;
    padding: 13px 35px;
    font-size: 15px;
    color: #9ACCC9;
    border-radius: 100px;
}

.AboutSectionHome button:hover {
    background-color: #9ACCC9;
    border: 1px solid #9ACCC9;
    padding: 13px 35px;
    font-size: 15px;
    color: #fff;
    border-radius: 100px;
}

.CategorySectionHome h6 {
    color: #F4A492;
    font-size: 18px;
    font-family: "Laila", serif;
    margin-bottom: 20px;
}

.CategorySectionHome h3 {
    color: #000000;
    font-size: 3rem;
    font-weight: 700;
    font-family: "Playfair Display", serif;
    margin-bottom: 20px;
}

.CategorySectionHome p {
    color: #555555;
    font-size: 19px;
    font-weight: 400;
    font-family: "Laila", serif;
    margin-bottom: 25px;
}

.CategorySectionHome button {
    background-color: transparent;
    border: 1px solid #9ACCC9;
    padding: 13px 35px;
    font-size: 15px;
    color: #9ACCC9;
    border-radius: 100px;
}

.CategorySectionHome button:hover {
    background-color: #9ACCC9;
    border: 1px solid #9ACCC9;
    padding: 13px 35px;
    font-size: 15px;
    color: #fff;
    border-radius: 100px;
}


.SingleService {
    background-color: #fff;
    height: 100%;
    cursor: pointer;
    box-shadow: 0 0 25px 0 rgba(177, 86, 86, 0.05);
}

.SingleService:hover {
    background-color: #fff;
    height: 100%;
    transition: all .2s ease-in-out;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
}

.Services-Content {
    text-align: center;
    padding: 20px 0px 10px;
}

.Services-Content h2 {
    color: #1a1a1a;
    font-family: "Merriweather", serif;
    font-weight: 700;
    font-size: 18px;
    cursor: pointer;
}

.SectionFive {
    text-align: center;
    padding: 20px;
    padding-top: 20px;
    padding-bottom: 30px;
    font-family: "Playfair Display", serif;
}

.SectionFive h2 {
    font-size: 52px;
    font-weight: 800;
    margin-top: 35px;
}

.SectionFiveFirst img {
    border: 5px solid #fff;
    border-radius: 50px 0px 0px 50px;
    box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.1);
}

.SectionFiveSecond img {
    border: 5px solid #fff;
    border-radius: 0px 50px 0px 0px;
    box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.1);
}

.SectionFiveThird img {
    border: 5px solid #fff;
    border-radius: 0px 0px 50px 0px;
    box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.1);
}

.ServiceBox {
    padding: 30px 20px 20px;
    font-family: "Playfair Display", serif;
}

.ServiceBox h3 {
    font-size: 18px;
    font-weight: 400;
    padding-top: 15px;
}

.story-section {
    background-color: #fff;
    border-radius: 0px 0px 100px 0px;
    box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.1);
    padding: 60px;
    font-family: "Playfair Display", serif;
}

.story-section h3 {
    font-size: 30px;
    color: #000000;
    margin-bottom: 20px;
    font-weight: 800;
    font-family: "Playfair Display", serif;
}

.story-section p {
    font-size: 16px;
    color: #555555;
    margin-bottom: 25px;
    font-weight: 400;
    font-family: "Laila", serif;
}

.story-section-img img {
    border: 5px solid #fff;
    border-radius: 50px 0px 0px 0px;
    box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.1);
}


.story-section button {
    background-color: transparent;
    border: 1px solid #9ACCC9;
    padding: 13px 35px;
    font-size: 15px;
    color: #9ACCC9;
    border-radius: 100px;
}

.story-section button:hover {
    background-color: #9ACCC9;
    border: 1px solid #9ACCC9;
    padding: 13px 35px;
    font-size: 15px;
    color: #fff;
    border-radius: 100px;
}

.ReservedSection img {
    border: 5px solid #fff;
    border-radius: 0px 100px 0px 50px;
    box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.1);
}


.FirstButton {
    background-color: #9ACCC9;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 25px;
    font-size: 13px;
    padding: 13px 50px;
    margin: 10px 0px;
    font-weight: 600;
    font-family: "Playfair Display", serif;
}

.FirstButton:hover {
    background-color: #fff;
    border: 1px solid #fff;
    color: #9ACCC9;
    border-radius: 25px;
    font-size: 13px;
    font-family: "Playfair Display", serif;
    padding: 13px 50px;
    margin: 10px 0px;
    font-weight: 600;
}

.CommonImg img {
    border: 3px solid #fff;
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.2);
}

.birdUp {
    position: relative;
}

.birdDown {
    position: absolute;
    bottom: 50px;
    left: -55%;
}

.birdUpSecond {
    position: relative;
}

.birdDownSecond {
    position: absolute;
    bottom: 90px;
    left: -10%;
}

.BirdUpThird {
    position: relative;
}

.BirdDownThird {
    position: absolute;
    left: 200px;
}

.HeartsUp {
    position: relative;
}

.HeartsDown {
    position: absolute;
    top: -100px;
    left: -25%;
}

.HeartsUpFooter {
    position: relative;
}

.HeartsDownFooter {
    position: absolute;
    top: -100px;
    left: -25%;
}

.HeartsUpSecond {
    position: relative;
}

.HeartsDownSecond {
    position: absolute;
    top: -40px;
    right: -20%;
}

.MapSize{
    height: 450px;
}

@media screen and (max-width: 1085px) {
    .HeartsDown {
        position: absolute;
        top: -60px;
        left: -15%;
    }

    .HeartsDownFooter {
        position: absolute;
        top: -60px;
        left: -15%;
    }

    .HeartsDown img {
        width: 50%;
    }

    .HeartsDownFooter img {
        width: 50%;
    }

    .HeartsUpSecond img {
        width: 50%;
    }

    .HeartsUpSecond {
        position: relative;
    }

    .HeartsDownSecond {
        position: absolute;
        top: -60px;
        right: -8%;
    }

    .HomeSet2-1 img {
        width: 320px;
        border: 5px solid #fff;
        border-radius: 0px 50px 0px 0px;
        box-shadow: -15px 15px 100px -53px rgba(0, 0, 0, 0.5);
    }

    .HomeSet2-2 img {
        width: 200px;
        border: 5px solid #fff;
        border-radius: 50px 0px 50px 0px;
        box-shadow: -20px -10px 100px -50px rgba(0, 0, 0, 0.5)
    }
}

@media screen and (max-width: 990px) {
    .HomeSet2-1 img {
        width: 260px;
        border: 5px solid #fff;
        border-radius: 0px 50px 0px 0px;
        box-shadow: -15px 15px 100px -53px rgba(0, 0, 0, 0.5);
    }

    .HomeSet2-2 img {
        width: 150px;
        border: 5px solid #fff;
        border-radius: 50px 0px 50px 0px;
        box-shadow: -20px -10px 100px -50px rgba(0, 0, 0, 0.5)
    }


    .HomeSet2-3 img {
        width: 100%;
        border: 5px solid #fff;
        margin-top: 20px;
        border-radius: 50px 0px 50px 0px;
        box-shadow: 6px -16px 100px -31px rgba(0, 0, 0, 0.5);
    }

    .MainImg {
        height: 1300px;
    }

    .MapSize{
        height: 350px;
    }
}

@media screen and (max-width: 767px) {
    .CoupleImg {
        height: 120vh;
    }

    .HeartsDown {
        position: absolute;
        top: -10px;
        left: -15%;
    }

    .HeartsDownFooter {
        position: absolute;
        top: -10px;
        left: -15%;
    }

    .HeartsDownFooter img {
        width: 50%;
    }

    .HeartsDown img {
        width: 50%;
    }

    .HeartsUpSecond img {
        width: 50%;
    }

    .HeartsUpSecond {
        position: relative;
    }

    .HeartsDownSecond {
        position: absolute;
        top: 0px;
        right: -8%;
    }

    .HomeSet2-1 img {
        width: 300px;
        border: 5px solid #fff;
        border-radius: 0px 50px 0px 0px;
        box-shadow: -15px 15px 100px -53px rgba(0, 0, 0, 0.5);
    }

    .HomeSet2-2 img {
        width: 190px;
        border: 5px solid #fff;
        border-radius: 50px 0px 50px 0px;
        box-shadow: -20px -10px 100px -50px rgba(0, 0, 0, 0.5)
    }

    .BirdUpThird {
        position: relative;
    }

    .BirdDownThird {
        position: absolute;
        left: 150px;
    }

    .BirdDownThird img {
        width: 70px;
    }

    .AboutSectionHome h5 {
        color: #555555;
        font-size: .8rem;
        font-weight: 400;
        font-family: "Laila", serif;
        margin-bottom: 20px;
    }

   
    


}

@media screen and (max-width: 450px) {
    .CoupleImg {
        height: 70vh;
    }

    .ServiceBox img {
        width: 80%;
    }


    .HeartsDown img {
        width: 50%;
    }

    .BirdUpThird {
        position: relative;
    }

    .BirdDownThird {
        position: absolute;
        left: 80px;
        bottom: 10px;
    }

    .BirdDownThird img {
        width: 50px;
    }

    .HeartsDownFooter {
        position: absolute;
        top: -10px;
        left: -2%;
    }

    .HeartsDownFooter img {
        width: 50%;
    }

    .MapSize{
        height: 200px;
    }
    
}
.MainTestimonial {
    background-color: #fff;
    padding: 20px 100px 30px 0px;
    border-radius: 30px 30px 0px 30px;
    font-family: "Laila", serif;
}


.carousel-control-next,
.carousel-control-prev {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: auto;
    bottom: auto;
    text-align: center;
    opacity: 1;
    z-index: 1;
}

.single-item {
    color: #111;
    margin: 10px 50px 0px;
}

.LeftArrow {
    width: 40px;
    height: 40px;
    margin-top: 100px;
    border-radius: 50px;
    background-color: #9ACCC9;
}

.LeftArrow:hover {
    width: 40px;
    height: 40px;
    margin-top: 100px;
    border-radius: 50px;
    background-color: #F4A492;
}

.LeftArrow i {
    font-size: 15px;
    margin-top: 12px;
}

.RightArrow {
    width: 40px;
    height: 40px;
    margin-top: 100px;
    border-radius: 50px;
    background-color: #9ACCC9;
}

.RightArrow:hover {
    width: 40px;
    height: 40px;
    margin-top: 100px;
    border-radius: 50px;
    background-color: #F4A492;
}

.RightArrow i {
    font-size: 15px;
    margin-top: 12px;
}


.RecentProjectMainContent {
    text-align: center;
}

.RecentProjectMainContent h2 {
    font-family: "Merriweather", serif;
    color: #fff;
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 20px;
    line-height: 46px;
}

.RecentProjectMainContent p {
    color: #808080;
    font-family: "Laila", serif;
    font-weight: 400;
    font-size: 20.5px;
    line-height: 27px;
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;
    margin-bottom: 25px;
}

.BirdUpThirdTestimonial img {
    width: 100%;
}

.BirdUpThirdTestimonial {
    position: relative;
}

.BirdDownThirdTestimonial {
    position: absolute;
    left: -90px;
}

.ClientReview {
    text-align: center;
}

.ClientReview h3 {
    font-family: "Playfair Display", serif;
    font-size: 25px;
    margin-top: 20px;
    margin-bottom: 5px;
    font-weight: 600;
}

.ClientReview h6 {
    font-family: "Playfair Display", serif;
    font-size: 15px;
    font-weight: 400;
}

.ClientReview p {
    font-size: 17px;
    color: #555555;
}




@media only screen and (max-width: 1200px) {
    .testimonials {
        width: 100%;
        height: 100%;
    }

    .MainTestimonial {
        background-color: #fff;
        padding: 10px 10px 10px 0px;
        border-radius: 30px 30px 0px 30px;
        font-family: "Laila", serif;
    }

    .LeftArrow {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        background-color: #9ACCC9;
    }

    .RightArrow {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        background-color: #9ACCC9;
    }

    .single-item {
        color: #111;
        margin: 10px 10px 0px;
    }

}

@media only screen and (max-width: 990px) {
    .MainTestimonial {
        background-color: #fff;
        padding: 10px 10px 10px 0px;
        border-radius: 30px 30px 0px 30px;
        font-family: "Laila", serif;
    }

    .carousel-control-next,
    .carousel-control-prev {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: auto;
        bottom: auto;
        /* width: 93%; */
        text-align: center;
        opacity: 1;
        z-index: 1;
    }

    .LeftArrow {
        width: 40px;
        height: 40px;
        margin-top: -250px;
        border-radius: 50px;
        background-color: #9ACCC9;
    }
    
    .LeftArrow:hover {
        width: 40px;
        height: 40px;
        margin-top: 10px;
        border-radius: 50px;
        background-color: #F4A492;
    }
    
    .LeftArrow i {
        font-size: 15px;
        margin-top: 12px;
    }
    
    .RightArrow {
        width: 40px;
        height: 40px;
        margin-top: -250px;
        border-radius: 50px;
        background-color: #9ACCC9;
    }
    
    .RightArrow:hover {
        width: 40px;
        height: 40px;
        margin-top: 10px;
        border-radius: 50px;
        background-color: #F4A492;
    }
    
    .RightArrow i {
        font-size: 15px;
        margin-top: 12px;
    }

}

@media only screen and (max-width: 767px) {



    .testimonials {
        width: 100%;
        height: 100%;
    }

    .LeftArrow {
        margin-right: 0px;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        background-color: #9ACCC9;
    }

    .RightArrow {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        background-color: #9ACCC9;
    }

    .LeftArrow:hover {
        margin-right: 0px;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        background-color: #F4A492;
    }

    .RightArrow:hover {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        background-color: #F4A492;
    }


    .MainTestimonial {
        background-color: #fff;
        padding: 15px 10px 10px 0px;
        text-align: center;
        border-radius: 30px 30px 0px 30px;
        font-family: "Laila", serif;
    }

    .carousel-control-next,
    .carousel-control-prev {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: auto;
        bottom: auto;
        margin-top: 180px;
        width: 93%;
        text-align: center;
        opacity: 1;
        z-index: 1;
    }



}

@media only screen and (max-width: 580px) {
    .testimonials {
        width: 100%;
        height: 100%;
    }

    .LeftArrow {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        background-color: #9ACCC9;
    }

    .RightArrow {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        background-color: #9ACCC9;
    }

    .LeftArrow:hover {
        margin-right: 0px;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        background-color: #F4A492;
    }

    .RightArrow:hover {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        background-color: #F4A492;
    }

    .single-item {
        color: #111;
        margin: 10px 5px 0px;
    }

    .image img {
        width: 100%;
        height: auto;
        display: block;
    }

    .CompanyName {
        background-color: black;
        font-family: "Merriweather", serif;
        padding: 10px;
        text-align: center;
        color: #fff;
    }


    .carousel-control-next,
    .carousel-control-prev {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: auto;
        bottom: auto;
        width: 89%;
        text-align: center;
        opacity: 1;
        z-index: 1;
    }

}

@media only screen and (max-width: 380px) {


    .carousel-control-next,
    .carousel-control-prev {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: auto;
        bottom: auto;
        width: 85%;
        text-align: center;
        opacity: 1;
        z-index: 1;
    }

}
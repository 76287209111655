.laila-semibold {
    font-family: "Laila", serif;
    font-weight: 600;
    font-style: normal;
}

.lato-black {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-style: normal;
}

.merriweather-bold {
    font-family: "Merriweather", serif;
    font-weight: 700;
    font-style: normal;
}



.navbar-nav {
    display: flex;
    width: 100%;
    padding: 0px 25px 0px 0px;
    font-family: "Laila", serif;
    font-weight: 600;
}

.navbar-nav .nav-link {
    color: #111;
    padding: 40px;
    font-size: .8411rem;
}

.LogoSection {
    padding-top: 21px;
    font-family: "Merriweather", serif;
}

.LogoSection span {
    color: #2bc0c1;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.2;
}

.LogoSection h4 {
    color: #fff;
    font-size: 35px;
    font-weight: 700;
    line-height: 1.2;
}

.header-logo-contact-section-btn {
    background-color: #9ACCC9;
    border: 2px solid #9ACCC9;
    border-radius: 100px;
    padding: 10px 22px;
    text-transform: uppercase;
    font-size: 13px;
    color: #fff;
}

.header-logo-contact-section-btn:hover {
    background-color: #F4A492;
    border: 2px solid #F4A492;
    padding: 10px 22px;
    text-transform: uppercase;
    font-size: 13px;
    color: #fff;
}

.ExtraContent{
    color: #111;
}

.ExtraContent:hover{
    color: #fff;
}

.dropend {
    color: black;
    font-weight: 400;
    padding: 10px;
    font-size: 14px;
    /* margin-left: -5px; */
}

.dropend:hover {
    background-color: #F4A492;
    color: #fff;
    font-weight: 400;
    padding: 10px;

}



.dropdown-item {
    color: black;
    background-color: #fff;
    padding: 10px;
    font-size: 14px;
    padding-left: 15px;
    /* width: 180px; */
}

.dropdown-item:hover {
    background-color: #F4A492;
    color: #fff;
    font-size: 14px;

    /* width: 180px; */
}

.MainPadding{
    padding-right: 45px;
}

.dropdown-item.active {
    background-color: #fff;
    color: black;
}

.dropdown-item.active:hover {
    background-color: #F4A492;
    color: #fff;
}

.dropdown .dropdown-menu {
    display: none;
    width: 190px;
}

.dropdown:hover>.dropdown-menu,
.dropend:hover>.dropdown-menu {
    display: block;
    padding: 0px;
    border: none;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    font-size: .5rem;
    background-color: #fff;
    border-radius: 0px;
}

@media screen and (min-width: 1200px) {
    .dropend:hover>.dropdown-menu {
        position: absolute;
        top: 0;
        left: 100%;
    }

    .dropend .dropdown-toggle {
        margin-left: 0.5em;
    }

   
}

@media screen and (max-width: 1085px) {

    .MainPadding{
        padding-right: 30px;
    }
}


@media screen and (min-width: 1000px) {
    .dropend:hover>.dropdown-menu {
        position: absolute;
        top: 0;
        left: 100%;
    }

    .dropend .dropdown-toggle {
        margin-left: 0.5em;
    }


}


@media screen and (max-width: 990px) {
    .navbar>.container-fluid {
        display: flex;
        /* flex-direction: column; */
        align-items: unset;
        justify-content: space-between;
    }

    .navbar-nav .nav-link {
        color: #fff;
        padding: 7px 0px;
        font-size: .8411rem;
    }

    .nav-item {
        border-top: none;
    }

    .nav-item:hover {
        border-top: none;
    }

    .navbar-toggler {
        margin-bottom: 0px;
        margin-top: 0px;
        border-radius: 0px;
        line-height: 1.1;
    }



    .navbar-toggler i {
        background-color: #fff;
        font-size: 1.25rem;
        border: #202734;
        padding: 0.70rem 0.66rem;
        color: #fff;
    }

    .navbar-toggler:focus {
        text-decoration: none;
        box-shadow: none;
    }

    .LogoSection h4 {
        color: #fff;
        font-size: 35px;
        font-weight: 700;
        margin-left: 10px;
        line-height: 1.2;
    }
}
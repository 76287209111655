.MainPricing {
    box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.1);
    padding: 25px;
    border-radius: 0px 50px 0px 50px;
}

.MainPricing img {
    border: 5px solid #fff;
    border-radius: 0px 50px 0px 50px;
    margin-top: 10px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.1);
}

.MainPricing h2 {
    color: black;
    font-size: 30px;
    margin-top: 25px;
    margin-bottom: 25px;
    font-weight: 600;
    font-family: "Playfair Display", serif;
}

.MainPricing p {
    color: #555555;
    font-size: 18px;
    margin-bottom: 25px;
    font-weight: 400;
    font-family: "Laila", serif;
}

.MainPricing h3 {
    color: #9ACCC9;
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 25px;
    font-weight: 600;
    font-family: "Playfair Display", serif;
}


.MainPricing h6 {
    color: #555555;
    font-size: 15px;
    padding-bottom: 5px;
    margin-bottom: 20px;
    font-weight: 400;
    font-family: "Laila", serif;
}

.PricingTableMainSecondButton {
    background-color: #fff;
    border: 1px solid #9ACCC9;
    color: #9ACCC9;
    border-radius: 25px;
    font-size: 15px;
    padding: 15px 50px;
    margin: 20px 0px;
    font-weight: 400;
}

.PricingTableMainSecondButton:hover {
    background-color: #9ACCC9;
    border: 1px solid #9ACCC9;
    color: #fff;
    border-radius: 25px;
    font-size: 15px;
    padding: 15px 50px;
    margin: 20px 0px;
    font-weight: 400;
}
.RecentPostTop h2 {
    font-family: "Playfair Display", serif;
    font-weight: 700;
    font-size: 40px;
}

.FooterSectionFirst button {
    background-color: #F4A492;
    border: 2px solid #F4A492;
    color: #fff;
    border-radius: 25px;
    font-size: 14px;
    padding: 15px 50px;
    margin: 10px 0px;
    font-weight: 600;
    font-family: "Playfair Display", serif;
}

.FooterSectionFirst button:hover {
    background-color: #33A69A;
    border: 2px solid #33A69A;
    color: #fff;
    border-radius: 25px;
    font-size: 14px;
    font-family: "Playfair Display", serif;
    padding: 15px 50px;
    margin: 10px 0px;
    font-weight: 600;
}

.FooterSectionSecond button {
    background-color: #fff;
    border: 2px solid #33A69A;
    color: #33A69A;
    border-radius: 25px;
    font-size: 14px;
    padding: 15px 50px;
    margin: 10px 0px;
    font-weight: 600;
    font-family: "Playfair Display", serif;
}

.FooterSectionSecond button:hover {
    background-color: #33A69A;
    border: none;
    color: #fff;
    border-radius: 25px;
    font-size: 14px;
    font-family: "Playfair Display", serif;
    padding: 15px 50px;
    margin: 10px 0px;
    font-weight: 600;
}



.FooterHover {
    display: flex;
    color: #6c6c6c;
    font-size: 14px;
    line-height: 15px;
    font-weight: 400;
}

.FooterHover:hover {
    color: #fe9505;
    font-size: 14px;
    line-height: 15px;
    font-weight: 400;
}

.FooterHover i {
    color: #659833;
    font-size: 14px;
    line-height: 15px;
    font-weight: 800;
}

.FooterHover i:hover {
    color: #fe9505;
    font-size: 14px;
    line-height: 15px;
    font-weight: 800;
}

.FooterHoverFirst {
    display: flex;
    align-items: center;
    color: #6c6c6c;
    font-size: 14px;
    line-height: 15px;
    font-weight: 600;
}

.FooterHoverFirst:hover {
    color: #fe9505;
    font-size: 14px;
    line-height: 15px;
    font-weight: 600;
}

.FooterHoverFirst i {
    background-color: #659833;
    color: #fff;
    width: 40px;
    height: 40px;
    padding-top: 12px;
    text-align: center;
    border-radius: 50%;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 800;
}

.FooterHoverFirst i:hover {
    background-color: #fe9505;
    color: #fff;
    width: 40px;
    height: 40px;
    padding-top: 12px;
    text-align: center;
    border-radius: 50%;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 800;
}

.SocialIconFooter {
    margin-top: 25px;
    margin-bottom: 10px;
}

.SocialIconFooterFirst {
    background-color: #3b5998;
    width: 38px;
    height: 38px;
    font-size: 16px;
    color: #fff;
    margin-left: 10px;
    padding: 10px;
    border-radius: 25px;
}

.SocialIconFooterFirst:hover {
    background-color: #3b5998;
    width: 38px;
    height: 38px;
    font-size: 16px;
    transform: scale(1.1);
    color: #fff;
    margin-left: 10px;
    padding: 10px;
    border-radius: 25px;
}

.SocialIconFooterSecond {
    background-color: #1da1f2;
    width: 38px;
    height: 38px;
    font-size: 16px;
    color: #fff;
    margin-left: 10px;
    padding: 10px;
    border-radius: 25px;
}

.SocialIconFooterSecond:hover {
    background-color: #1da1f2;
    width: 38px;
    height: 38px;
    font-size: 16px;
    transform: scale(1.1);
    color: #fff;
    margin-left: 10px;
    padding: 10px;
    border-radius: 25px;
}

.SocialIconFooterThird {
    background-color: #0077b5;
    width: 38px;
    height: 38px;
    font-size: 16px;
    color: #fff;
    margin-left: 10px;
    padding: 10px;
    border-radius: 25px;
}

.SocialIconFooterThird:hover {
    background-color: #0077b5;
    width: 38px;
    height: 38px;
    font-size: 16px;
    transform: scale(1.1);
    color: #fff;
    margin-left: 10px;
    padding: 10px;
    border-radius: 25px;
}

.SocialIconFooterFour {
    background-color: #dd4b39;
    width: 38px;
    height: 38px;
    font-size: 16px;
    color: #fff;
    margin-left: 10px;
    padding: 10px;
    border-radius: 25px;
}

.SocialIconFooterFour:hover {
    background-color: #dd4b39;
    width: 38px;
    height: 38px;
    font-size: 16px;
    transform: scale(1.1);
    color: #fff;
    margin-left: 10px;
    padding: 10px;
    border-radius: 25px;
}

.MainFooter p {
    font-size: 14px;
    line-height: 25px;
    font-weight: 400;
    color: #555555;
}

@media screen and (max-width: 990px) {
    .FooterSectionFirst button {
        background-color: #F4A492;
        border: 2px solid #F4A492;
        color: #fff;
        border-radius: 25px;
        text-align: start;
        font-size: 10px;
        padding: 12px 40px;
        /* margin: 10px 0px; */
        font-weight: 600;
        font-family: "Playfair Display", serif;
    }

    .FooterSectionFirst button:hover {
        background-color: #33A69A;
        border: 2px solid #33A69A;
        color: #fff;
        border-radius: 25px;
        font-size: 10px;
        font-family: "Playfair Display", serif;
        padding: 12px 40px;
        margin: 10px 0px;
        font-weight: 600;
    }

    .FooterSectionSecond button {
        background-color: #fff;
        border: 2px solid #33A69A;
        color: #33A69A;
        border-radius: 25px;
        font-size: 10px;
        padding: 12px 40px;
        /* margin: 10px 0px; */
        font-weight: 400;
        font-family: "Playfair Display", serif;
    }
    
    .FooterSectionSecond button:hover {
        background-color: #33A69A;
        border: none;
        color: #fff;
        border-radius: 25px;
        font-size: 10px;
        font-family: "Playfair Display", serif;
        padding: 12px 40px;
        margin: 10px 0px;
        font-weight: 600;
    }
}